/* MODAL STYLES
-------------------------------*/
app-modal {
  /* modals are hidden by default */
  display: none;

  .modal {
    align-items: center;
    display: flex;
    justify-content: center;
    width: fit-content;
    // height: fit-content;
    height: unset;
    margin: auto;

    /* modal container fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* z-index must be higher than .modal-background */
    z-index: 1000 !important;

    &.second-layer {
      z-index: 12000 !important;
    }

    /* enables scrolling for tall modals */
    overflow-y: auto;

    .modal-body {
      padding: 0px;
      background: white;
      border-radius: 10px;
      /* margin exposes part of the modal background */
      // margin: 40px;

      &.modal-md {
        width: 500px;
        height: 600px;
      }

      &.modal-sm {
        width: 400px;
        height: 274px;
      }

      &.modal-xs {
        width: 400px;
      }
    }
  }

  .modal-background {
    /* modal background fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* semi-transparent black  */
    background-color: #000;
    opacity: 0.75;

    /* z-index must be below .modal and above everything else  */
    z-index: 900;

    &.second-layer {
      background-color: #000;
      opacity: 0.5;
      z-index: 1100;
    }
  }
}

body {
  .modal-open {
    /* body overflow is hidden to hide main scrollbar when modal window is open */
    overflow: hidden;
  }
}
